<template>
  <v-container>
    <v-row justify="space-between">
      <v-col class="mt-4" cols="6">
        <v-progress-circular :width="3" color="green" :indeterminate="load" v-if="showLoad"
          class="mt-5"></v-progress-circular>
      </v-col>
      <v-col cols="6" align="right">
        <v-btn-toggle v-model="timeStatus" color="primary">
          <v-btn text small style="height: 50px" value="week">Weekly</v-btn>
          <v-btn text small style="height: 50px" value="month"> Monthly </v-btn>

          <v-btn text small style="height: 50px" value="quarterly">
            Quarterly
          </v-btn>
          <v-btn text small style="height: 50px" value="year"> 1 Year </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" v-if="stats">
        <v-card class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1 mt-n2" color="white" flat>
          <v-list-item>
            <v-list-item-content class="pa-2">
              <v-list-item-title class="text-h5 mb-1">
                {{ stats.DriverTotal ? stats.DriverTotal : 0 }} <br />
                <v-list-item-subtitle>
                  <span class="black--text">Total Driver </span>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span>
                        <v-icon small v-bind="attrs" v-on="on">
                          mdi-help-circle
                        </v-icon>
                      </span>
                    </template>
                    <span> Total Number of Professionals </span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1 mt-n2" color="white" flat>
          <v-list-item>
            <v-list-item-content class="pa-2">
              <v-list-item-title class="text-h5 mb-1">
                {{ stats.DriverBanned ? stats.DriverBanned : 0 }}
                <br />
                <v-list-item-subtitle>
                  <span class="black--text">Block Driver </span>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span>
                        <v-icon small v-bind="attrs" v-on="on">
                          mdi-help-circle
                        </v-icon>
                      </span>
                    </template>
                    <span> Total Number of Block Professionals </span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1 mt-n2" color="white" flat>
          <v-list-item>
            <v-list-item-content class="pa-2">
              <v-list-item-title class="text-h5 mb-1">
                {{ stats.DriverActiveTotal ? stats.DriverActiveTotal : 0 }} <br />
                <v-list-item-subtitle>
                  <span class="black--text">Active Driver </span>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span>
                        <v-icon small v-bind="attrs" v-on="on">
                          mdi-help-circle
                        </v-icon>
                      </span>
                    </template>
                    <span> Total Number of Active Professionals </span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="rounded-tl rounded-tr rounded-bl rounded-br mt-n2 elevation-1" color="white" flat>
          <v-list-item>
            <v-list-item-content class="pa-2">
              <v-list-item-title class="text-h5 mb-1">
                {{ stats.DriverInActiveTotal ? stats.DriverInActiveTotal : 0 }} <br />
                <v-list-item-subtitle>
                  <span class="black--text">InActive Driver </span>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span>
                        <v-icon small v-bind="attrs" v-on="on">
                          mdi-help-circle
                        </v-icon>
                      </span>
                    </template>
                    <span> Total Number of InActive Professionals </span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="rounded-tl rounded-tr rounded-bl rounded-br mt-n4 elevation-1" color="white" flat>
          <v-list-item>
            <v-list-item-content class="pa-2">
              <v-list-item-title class="text-h5 mb-1">
                {{ stats.inActive_Driver_growth_rate ? stats.inActive_Driver_growth_rate : 0 }}%<br />
                <v-list-item-subtitle>
                  <span class="black--text">Inactive Driver Growth Rate
                  </span>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span>
                        <v-icon small v-bind="attrs" v-on="on">
                          mdi-help-circle
                        </v-icon>
                      </span>
                    </template>
                    <span> Total Growth Rate of the Inactive Professionals </span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="rounded-tl rounded-tr rounded-bl rounded-br elevation-1 mt-n4" color="white" flat>
          <v-list-item>
            <v-list-item-content class="pa-2">
              <v-list-item-title class="text-h5 mb-1">
                {{ stats.Driver_growth_rate ? stats.Driver_growth_rate : 0 }}%<br />
                <v-list-item-subtitle>
                  <span class="black--text">Growth Rate </span>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span>
                        <v-icon small v-bind="attrs" v-on="on">
                          mdi-help-circle
                        </v-icon>
                      </span>
                    </template>
                    <span> Total Growth Rate </span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="rounded-tl rounded-tr rounded-bl rounded-br mt-n4 elevation-1" color="white" flat>
          <v-list-item>
            <v-list-item-content class="pa-2">
              <v-list-item-title class="text-h5 mb-1">
                {{ stats.avgRating[0] ? stats.avgRating[0].AverageRating : 0 }}
                <br />
                <v-list-item-subtitle>
                  <span class="black--text">Avg Rating Score </span>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span>
                        <v-icon small v-bind="attrs" v-on="on">
                          mdi-help-circle
                        </v-icon>
                      </span>
                    </template>
                    <span> Average Customer Satisfaction Score </span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-container>
          <base-material-card color="primary">
            <template v-slot:heading>
              <div class="text-h3 font-weight-light">Drivers Locations</div>
            </template>
            <v-card v-if="adminPosition" elevation="0" class="pa-2">
              <template>
                <GmapMap :center="adminPosition" :zoom="14" map-type-id="terrain" style="max-width: 150rem; height: 30rem"
                  :options="{
                    styles: [
                      {
                        'featureType': 'poi',
                        'stylers': [{ 'visibility': 'off' }]
                      },
                      // {
                      //   'featureType': 'administrative',
                      //   'elementType': 'geometry',
                      //   'stylers': [{ color: '#d59563', 'visibility': 'off' }]
                      // },
                      // {
                      //   'featureType': 'road',
                      //   'elementType': 'labels.icon',
                      //   'stylers': [{ color: '#d59563', 'visibility': 'off' }]
                      // },
                      // {
                      //   'featureType': 'transit',
                      //   'stylers': [{ color: '#d59563', 'visibility': 'off' }]
                      // }
                    ]
                  }">
                  <GmapMarker :key="'driver_' + index" v-for="(m, index) in driverLocations" :label="m.label"
                    :infoText="m.infoText" :position="m.position" :clickable="true" @click="m._id && goToProfile(m._id)"
                    :icon="markerOptions" @mouseover="m.showDetails = true" @mouseout="m.showDetails = false">
                    <GmapInfoWindow :options="{ maxWidth: 200 }" v-if="m.showDetails">
                      <div>
                        <p><strong>{{ m.name }}</strong></p>
                        <p>{{ m.phone }}</p>
                      </div>
                    </GmapInfoWindow>
                  </GmapMarker>

                  <GmapMarker :key="'listing_' + index" v-for="(listing, index) in activeListings"
                    :position="listing.pickup.position" :clickable="true" :icon="listingMarkerOptions"
                    class="pulsating-marker" @click="listing._id && goToBooking(listing._id)"
                    @mouseover="listing.showDetails = true" @mouseout="listing.showDetails = false">
                    <GmapInfoWindow :options="{ maxWidth: 200 }" v-if="listing.showDetails">
                      <div>
                        <p><strong>{{ listing.customer.firstName }} {{ listing.customer.surName }}</strong></p>
                        <p>{{ listing.customer.phone }}</p>
                        <p>{{ listing.pickup.address }}</p>
                      </div>
                    </GmapInfoWindow>
                  </GmapMarker>


                </GmapMap>
              </template>
            </v-card>
            <h2 v-else>Please allow your location access to see drivers around you.</h2>
          </base-material-card>
        </v-container>

      </v-col>
    </v-row>

    <v-row justify="space-between">
      <v-col cols="5">
        <v-btn-toggle v-model="usersStatus" color="primary">
          <v-btn text small style="height: 50px" value="all"> All </v-btn>

          <v-btn text small style="height: 50px" value="active"> Active </v-btn>
          <v-btn text small style="height: 50px" value="pending">
            Pending
          </v-btn>

          <v-btn text small style="height: 50px" value="incomplete">
            Incomplete
          </v-btn>

          <v-btn text small style="height: 50px" value="blocked">
            Blocked
          </v-btn>

          <v-btn text small style="height: 50px" value="deleted">
            Deleted
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="6" class="mt-3">
        <v-row>
          <div style="width: 200px">
            <date-filter :init-date="initDate" @dateChange="onDateChange" />
          </div>
          <v-text-field label="Search Drivers" solo append-icon="mdi-magnify" @change="search" style="width: 200px" />
          <v-btn color="primary" class="ml-4" @click="userFormOpen = true"
            style="width: 95px; height: 47px; margin-left: 5px">
            <v-icon small> mdi-plus </v-icon>
            Add New
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card>
      <v-data-table :headers="headers" :items="users.data" :options.sync="tableOptions" :loading="loading"
        class="elevation-1" :footer-props="{
          'items-per-page-options': tableOptions.rowsPerPageItems,
        }">
        <template v-slot:[`item.first_name`]="{ item }">
          <div>
            {{ item.firstName }} {{ item.surName }}
            <div v-if="item.deletedAt" class="custom-tooltip badge-deleted">
              Deleted
              <span class="tooltip-content">{{ getDeletedTooltip(item) }}</span>
            </div>
          </div>
        </template>


        <template v-slot:[`item.isVerified`]="{ item }">
          <v-chip class="ma-2" label outlined small :color="getColor(item)">
            {{ item.banned ? "BLOCKED" : item.profile_step < 6 ? "INCOMPLETE" : (item.isVerified ? "ACTIVE" : "PENDING")
            }} </v-chip>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ getReadableDate(item.createdAt) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">

          <v-tooltip :key="index" bottom v-if="item.isVerified === false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="pendingToggle(item)" :disabled="!!item.deletedAt">
                mdi-account-check
              </v-icon>
            </template>
            Active this user
          </v-tooltip>

          <v-tooltip :key="index" bottom v-if="item.banned === true">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="pendingToggle(item)" :disabled="!!item.deletedAt">
                mdi-account-check
              </v-icon>
            </template>
            Active this user
          </v-tooltip>

          <v-tooltip :key="index" bottom v-if="item.isVerified === true && item.banned === false"
            :disabled="!!item.deletedAt">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="blockToggle(item)" :disabled="!!item.deletedAt">
                mdi-account-cancel
              </v-icon>
            </template>
            Block this user
          </v-tooltip>

          <v-tooltip :key="index" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="goToProfile(item._id)" :disabled="!!item.deletedAt">
                mdi-eye
              </v-icon>
            </template>
            View
          </v-tooltip>

          <v-tooltip v-if="!item.deletedAt" :key="index" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="deleteCustomer(item)" :disabled="!!item.deletedAt">
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>

          <v-tooltip v-else :key="index" bottom :disabled="!!item.deletedAt">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on" disabled>
                mdi-delete
              </v-icon>
            </template>
            <span>Already deleted</span>
          </v-tooltip>

        </template>
      </v-data-table>
    </v-card>

    <user-form :open="userFormOpen" user-type="driver" @close="userModalClose" />
  </v-container>
</template>
<script>
import userForm from "@/components/userForm";
import {
  getDrivers,
  approveDriver,
  blockDriver,
  deleteDriver,
  getDriversDashboardStats,
} from "@/services/users";
import {
  getRequestLocations,
} from "@/services/admin";
import DateFilter from "@/components/dateFilter";

import firebaseDB from "../../firebaseInit";
import { getDatabase, ref, onValue } from "firebase/database";
import * as VueGoogleMaps from 'vue2-google-maps';

export default {
  components: { userForm, DateFilter },
  data() {
    return {
      activeListings: [],
      listingMarkerOptions: {
        url: "https://wichitpub.s3.eu-west-2.amazonaws.com/red_dot.png",
        size: { width: 40, height: 40, f: 'px', b: 'px' },
        scaledSize: { width: 40, height: 40, f: 'px', b: 'px' },
        className: 'pulsating-marker',
      },

      isInfoWindowVisible: false,
      currentDriver: {},

      markerOptions: {
        url: "https://wichitpub.s3.eu-west-2.amazonaws.com/truckIcon.png",
        size: { width: 40, height: 40, f: 'px', b: 'px', },
        scaledSize: { width: 40, height: 40, f: 'px', b: 'px', },
      },
      adminPosition: null,
      driverLocations: [],
      userFormOpen: false,
      usersStatus: "all",
      timeStatus: "week",
      load: false,
      showLoad: false,
      stats: null,
      headers: [

        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "first_name",
        },
        {
          text: "Phone No",
          align: "start",
          sortable: false,
          value: "phone",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "isVerified",
        },
        {
          text: "Registration Date",
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        {
          text: "Action",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      users: {
        data: [],
        actions: ["block", "view"],
      },
      tableOptions: {
        page: 1,
        itemsPerPage: 200,
        totalItems: 0,
        rowsPerPageItems: [200, 500, 1000, 2000],
      },
      loading: true,
      searchKeyword: "",
      dateFilter: null,
      initDate: [],
    };
  },
  watch: {
    tableOptions: {
      handler() {
        this.loading = true;
        const _self = this;
        setTimeout(function () {
          _self.getUsersData();
        }, 500);
      },
      deep: true,
    },
    usersStatus() {
      this.loading = true;
      this.getUsersData();
    },
    timeStatus() {
      this.getUsersTimeStats();
    },
  },
  async mounted() {
    window.onunload = function () {
      localStorage.removeItem('filterDate');
    }

    this.fetchActiveListings();
    setInterval(() => {
      this.fetchActiveListings();
    }, 60000); // 1 minutes

    // this.$gmapApiPromiseLazy().then(() => {
    //   this.listingMarkerOptions = {
    //     icon: {
    //       path: this.$gmapApi.maps.SymbolPath.CIRCLE,
    //       fillColor: 'red',
    //       fillOpacity: 0.8,
    //       strokeWeight: 0,
    //       scale: 10,
    //       // animation: this.$gmapApi.maps.Animation.BOUNCE, // Optional: Add a bounce animation
    //     },
    //     // options: {
    //     //   animation: this.$gmapApi.maps.Animation.DROP, // Optional: Add a drop animation
    //     // },
    //   };
    //   console.log('this.listingMarkerOptions', this.listingMarkerOptions);
    //   // this.togglePulsating();
    // });


    // const date = new Date();
    // if (!localStorage.getItem("filterDate")) {
    //   this.initDate.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    //   this.initDate.push(
    //     `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    //   );
    // } else {
    //   let datearr = localStorage.getItem("filterDate").split(",");
    //   let date1 = new Date(datearr[0]);
    //   let date2 = new Date(datearr[1]);
    //   this.initDate.push(
    //     `${date1.getFullYear()}-${date1.getMonth() + 1}-${date1.getDate()}`
    //   );
    //   this.initDate.push(
    //     `${date2.getFullYear()}-${date2.getMonth() + 1}-${date2.getDate()}`
    //   );
    // }
    this.getUsersData();
    this.getUsersTimeStats();
    this.getDriverLocations();
    this.getUserLocation();
  },
  methods: {
    getDeletedTooltip(item) {
      return `This user was deleted on ${this.getReadableDate(item.deletedAt)}`;
    },

    togglePulsating() {
      this.isPulsating = !this.isPulsating;

      // Toggle the class every second (adjust as needed)
      setTimeout(() => {
        this.togglePulsating();
      }, 1000);
    },
    async fetchActiveListings() {
      try {
        const response = await getRequestLocations({ status: "active" });
        this.activeListings = response.data.requests;
        this.activeListings = this.activeListings.map(item => ({ ...item, showDetails: false }))
        this.activeListings = this.activeListings.map(item => {
          item.customer = item.customer || {};

          item.customer.firstName = item.customer.firstName || "";
          item.customer.surName = item.customer.surName || "";
          item.customer.phone = item.customer.phone || "";

          return item;
        });
      } catch (error) {
        console.error('Error fetching active listings:', error);
      }
    },
    search(keyword) {
      this.searchKeyword = keyword;
      this.getUsersData();
    },
    userModalClose() {
      this.userFormOpen = false;
      this.getUsersData();
    },
    onDateChange(newValue) {
      if (newValue.length < 2) return;
      localStorage.setItem("filterDate", newValue);
      let finaldate = localStorage.getItem("filterDate").split(",");
      this.dateFilter = localStorage.getItem("filterDate")
        ? [new Date(finaldate[0]), new Date(finaldate[1])]
        : newValue;
      this.getUsersData();
    },
    async getUsersData() {
      this.loading = true;
      const params = {
        status: this.usersStatus === "all" ? null : this.usersStatus,
        search: this.searchKeyword,
        limit: this.searchKeyword ? 30 : this.tableOptions.itemsPerPage,
        page: this.tableOptions.page,
        type: "driver",
        date: this.searchKeyword ? null : this.dateFilter?.join(","),
      };
      getDrivers(params).then((res) => {
        this.users.data = res.data.Users;
        console.log("object")
        this.loading = false;
      });
      const userStatsParams = {
        status: this.timeStatus === "all" ? null : this.timeStatus,
      };
      getDriversDashboardStats(userStatsParams).then((res) => {
        this.stats = res.data;
        console.log(this.stats, "stats");
      });
    },
    async getUsersTimeStats() {
      this.load = true;
      this.showLoad = true;
      const userStatsParams = {
        status: this.timeStatus === "all" ? null : this.timeStatus,
      };
      getDriversDashboardStats(userStatsParams).then((res) => {
        this.stats = res.data;
        this.load = false;
        this.showLoad = false;
        console.log(this.stats);
      });
    },
    pendingToggle(item) {
      const status = item.status === "pending" ? "active" : "pending"; //extra
      approveDriver(item._id, { status: status })
        .then((res) => {
          this.getUsersData();
        })
        .catch(err => {
          alert(err.response.data.message.toString())
        });
    },
    blockToggle(item) {
      const status = item.status === "blocked" ? "active" : "blocked"; //extra
      blockDriver(item._id, { status: status }).then((res) => {
        this.getUsersData();
      });
    },
    goToProfile(id) {
      this.$router.push({ name: "Driver Profile", params: { id: id } });
    },
    goToBooking(id) {
      this.$router.push({ name: 'Request Detail', params: { id: id } });
    },
    getReadableDate(date) {
      const d = new Date(date);
      return d.toUTCString();
    },
    getColor(item) {
      return item.banned ? "red" : item.profile_step < 6 ? "#ff9966" : item.isVerified ? "green" : "orange"

    },
    deleteCustomer(item) {
      if (confirm("Are your Sure?")) {
        console.log(item);
        deleteDriver(item._id).then((res) => {
          console.log("asdf", res.data);
          if (res.data) {
            this.getUsersData();
            return alert("User Delete Successfully!");
          }
          else return alert("Try Again! Some thing went wrong.");
        });
        this.getUsersData();

      }
    },
    getDriverLocations() {
      const db = getDatabase();
      const driversLiveLocations = ref(db, `${process.env.VUE_APP_Path_Firebase ? process.env.VUE_APP_Path_Firebase : ''}` + '/driver_locations');
      onValue(driversLiveLocations, (snapshot) => {
        this.driverLocations = [];
        const data = Object.values(snapshot.val());
        // console.log("🚀 ~ file: profile.vue:2086 ~ snapshot.forEach ~ childSnapshot.val():", data)
        data.map(loc => {
          this.driverLocations.push(
            {
              position: {
                lat: loc?.driver_location?.latitude, lng: loc?.driver_location?.longitude,
              },
              name: loc?.driver_detail?.firstName && `${loc?.driver_detail?.firstName} ${loc?.driver_detail?.surName}`,
              phone: loc?.driver_detail?.phone || null,
              _id: loc?.driver_detail?._id,
              showDetails: false,
              //  label: "Driver Name"// this.userDetails.pickup?.location?.coordinates[1] ? "P" : "",
              // infoText: "Driver Detail"// this.userDetails.pickup?.location?.coordinates[1] ? "Ride Pickup Location" : "",
            },
          )
        })
        // console.log("🚀 ~ file: drivers.vue:624 ~ onValue ~ this.driverLocations:", this.driverLocations)

      });
    },
    getUserLocation() {
      console.log('get location');
      navigator.geolocation.getCurrentPosition(
        position => {
          console.log(position.coords.latitude);
          console.log(position.coords.longitude);
          this.adminPosition = {
            lat: position.coords.latitude, lng: position.coords.longitude,
          }
        },
        error => {
          console.log(error.message);
        },
      )
    },
  },
};
</script>

<style>
.pulsating-marker {
  animation: pulse 1.5s infinite;
  /* Adjust the duration as needed */
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.6;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.9);
    opacity: 0.6;
  }
}

.badge-deleted {
  background-color: red;
  /* Customize the badge background color */
  color: white;
  /* Customize the badge text color */
  padding: 2px 4px;
  /* Adjust padding as needed */
  border-radius: 6px;
  /* Adjust border-radius as needed */
  font-size: 10px;
  /* Adjust font size as needed */
}

.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.custom-tooltip:hover .tooltip-content {
  display: block;
}

.tooltip-content {
  display: none;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  top: 100%;
  left: 0;
  white-space: nowrap;
}
</style>